<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Nueva vacante</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Nombre de la vacante</label>
              <div class="control">
                <input
                  v-model="name"
                  class="input"
                  v-validate="'required'"
                  name="name"
                  type="text"
                  data-vv-as="nombre"
                >
                <span class="is-error-input">{{ vverrors.first('name') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Empresa</label>
              <div class="control">
                <input
                  v-model="company"
                  class="input"
                  v-validate="'required'"
                  name="company"
                  type="text"
                  data-vv-as="empresa"
                >
                <span class="is-error-input">{{ vverrors.first('company') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Área</label>
              <div class="control">
                <input
                  v-model="area"
                  class="input"
                  v-validate="'required'"
                  name="area"
                  type="text"
                  data-vv-as="área"
                >
                <span class="is-error-input">{{ vverrors.first('area') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Horario</label>
              <div class="control">
                <input
                  v-model="schedule"
                  v-validate="'required'"
                  name="schedule"
                  class="input"
                  type="text"
                  data-vv-as="horario"
                >
                <span class="is-error-input">{{ vverrors.first('schedule') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Ubicación</label>
               <div class="select is-fullwidth">
                <select
                  class="select"
                  v-model="location"
                  v-validate="'required'"
                  name="location"
                  data-vv-as="ubicación"
                >
                  <option disabled :value="null" selected>Selecciona una opción</option>
                  <option value="1">Sevilla</option>
                  <option value="2">Madrid</option>
                  <option value="3">Málaga</option>
                  <option value="4">Cadiz</option>
                  <option value="5">Huelva</option>
                  <option value="6">Almería</option>
                  <option value="7">Palencia</option>
                  <option value="8">Jaén</option>
                  <option value="9">Valladolid</option>
                  <option value="10">A Coruña</option>
                  <option value="11">Pontevedra</option>
                  <option value="12">Toledo</option>
                  <option value="13">Boiro</option>
                </select>
                <span class="is-error-input">{{ vverrors.first('location') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label">Salario</label>
              <div class="control">
                  <!-- v-validate="'required'" -->
                <input
                  v-model="salary"
                  name="salary"
                  class="input"
                  type="text"
                  placeholder="$10,000"
                  data-vv-as="salario"
                >
                <span class="is-error-input">{{ vverrors.first('salary') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <hr class="divider" />
          </div>
          <!-- <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Estatus</label>
              <div class="control">
                <input
                  v-model="password"
                  class="input"
                  v-validate="'required'"
                  name="password"
                  type="text"
                  data-vv-as="contraseña"
                >
                <span class="is-error-input">{{ vverrors.first('password') }}</span>
              </div>
            </div>
          </div> -->
          <div class="column is-12">
            <div class="field">
              <label class="label is-mandatory">Descripción</label>
              <div class="control">
                <input
                  v-model="description"
                  class="input"
                  v-validate="'required'"
                  name="description"
                  type="text"
                  data-vv-as="Descripción"
                >
                <span class="is-error-input">{{ vverrors.first('description') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="field">
              <label class="label is-mandatory">Requisitos</label>
              <div class="control">
                <input
                  v-model="requirements"
                  class="input"
                  v-validate="'required'"
                  name="requirements"
                  type="text"
                  data-vv-as="Requerimientos"
                >
                <span class="is-error-input">{{ vverrors.first('requirements') }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-small" :disabled="loading" @click="close">Cancelar</button>
        <button
          class="button is-active-b is-small"
          @click="saveUsuario"
          :disabled="loading"
          :class="{'is-loading': loading}"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalNuevoUsuario',
  props: {
    updateList: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    crearVacante: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      name: null,
      company: null,
      area: null,
      schedule: null,
      location: null,
      salary: null,
      description: null,
      requirements: null,
      loading: false
    }
  },
  methods: {
    async saveUsuario () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          name: this.name,
          company: this.company,
          area: this.area,
          schedule: this.schedule,
          location: this.location,
          salary: this.salary,
          description: this.description,
          requirements: this.requirements
        }
        await this.crearVacante(data)
        this.loading = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.divider {
  margin: 0.5rem 0;
}
.field {
  .label {
    font-size: 13px;
    text-align: left;
    margin-left: 0.2rem;
    font-weight: 500;
  }
}
</style>
